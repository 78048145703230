/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 06-06-2019.
 */

import React, { useRef, useState } from 'react'

import Player from '../video/Player'
import { Waypoint } from 'react-waypoint'
import './block.scss'

import gsap from 'gsap'
import Spinner from './Spinner'
import BlockGallery from './BlockGallery'
import Divider from './Divider'
import ReactGA from 'react-ga'

const Block = (props, ref) => {
  const imageMask = useRef()
  const spinnerRef = useRef()
  const txtRef = useRef()
  const titleRef = useRef()
  const container = useRef()
  const fadeInSpeed = props.isMobile ? 1 : 0.8
  const fadeOffset = props.isMobile ? 100 : 200

  const [inView, setInView] = useState(false)

  const isVideo = props.item.video ? true : false
  const url = props.item.url ? props.item.url : props.item.video

  let loadedUrl = ''

  const onEnter = (e) => {
    gsap.to(container.current, fadeInSpeed, { y: 0, delay: 0, alpha: 1, ease: 'Expo.easeOut' })

    if (!isVideo && !loadedUrl) {
      if (props.item.title) {
        //console.log (" Block > send event = ", props.item.title);
        ReactGA.pageview(props.item.title)
        ReactGA.event({
          category: props.item.title,
          action: 'visible',
        })
      }

      var img = new Image()
      const imagePath = url ? url : images[0]

      img.onload = () => {
        loadedUrl = 'url(' + imagePath + ')'
        gsap.to(imageMask.current, 1, {
          scaleY: 0,
          ease: 'Expo.easeOut',
          delay: 0.2,
          transformOrigin: 'center bottom',
        })
        gsap.to(spinnerRef.current, 1, {
          opacity: 0,
          ease: 'Expo.easeOut',
          delay: 0.2,
          transformOrigin: 'center bottom',
        })
      }
      img.src = imagePath
    }

    if (!inView) {
      setInView(true)
    }
  }

  const onLeave = (e) => {
    //gsap.to(titleRef.current, 0, { delay: 0, alpha: 0, ease: 'Expo.easeOut' })

    if (e.currentPosition === 'above' && e.previousPosition === 'inside') {
      gsap.to(container.current, 0, { y: -fadeOffset, delay: 0, alpha: 0, ease: 'Expo.easeOut' })
    } else {
      gsap.to(container.current, 0, { y: fadeOffset, delay: 0, alpha: 0, ease: 'Expo.easeOut' })
    }
  }

  const toggleBuffer = (value) => {
    gsap.to(imageMask.current, 1, { scaleY: 0, ease: 'Expo.easeOut', delay: 0.2, transformOrigin: 'center bottom' })
    gsap.to(spinnerRef.current, 1, {
      opacity: value ? 1 : 0,
      ease: 'Expo.easeOut',
      transformOrigin: 'center bottom',
    })
  }

  const maskColor = props.item.maskColor || '#cacaca'

  const title = props.item.title || 'Item Title'
  const txt =
    props.item.txt || 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sem arcu, vestibulum a magna non '
  const tags = props.item.tags || ' '
  const details = props.item.details || ''
  const role = props.item.role || ''
  const images = props.item.images || []
  const byline = props.item.byline || ''
  const credits = props.item.credits || ''
  const pad = props.item.aspect || '56.25%'

  return (
    <Waypoint
      fireOnRapidScroll={true}
      bottomOffset={0}
      topOffset={0}
      onEnter={onEnter}
      onLeave={onLeave}
      scrollableAncestor={window}
    >
      <div
        ref={container}
        className="row h-100 mt-5 Block "
        style={{ opacity: 0, transform: 'translate3d(0,100px,0)' }}
      >
        <div className="col-12 col-md-10 mx-auto w-100 position-relative" style={{}}>
          <div ref={spinnerRef} className="position-absolute w-100 h-100 " style={{ zIndex: 200, top: 0, left: 0 }}>
            <div className="d-flex justify-content-center align-items-center h-100 w-100">
              <div className="position-relative" style={{ left: 25, top: 20 }}>
                <Spinner></Spinner>
              </div>
            </div>
          </div>

          <div className="position-relative w-100 h-100" style={{ paddingTop: pad }}>
            <div className="position-absolute w-100 h-100" style={{ top: 0, left: 0, zIndex: 100 }}>
              <div ref={imageMask} className="w-100 h-100" style={{ zIndex: 100, background: maskColor }}></div>
            </div>

            {isVideo && inView ? <Player toggleBuffer={toggleBuffer} url={url}></Player> : null}

            {!isVideo && inView ? (
              <div className="w-100 h-100 ">
                {images.length > 1 ? (
                  <BlockGallery inView={inView} title={title} items={images} index={props.index}></BlockGallery>
                ) : (
                  <div
                    className="back-image h-100 w-100 position-absolute block-shadow"
                    style={{ backgroundImage: 'url(' + images[0] + ')', top: 0, left: 0 }}
                  ></div>
                )}
              </div>
            ) : null}
          </div>
        </div>

        <div className="col-12 col-md-8 mx-auto mb-md-5 mb-0" style={{ userSelect: 'none' }}>
          <div className="row" style={{}}>
            <div className="col-12 mt-4 mt-md-5 mb-2 mb-md-6 ">
              <h1 ref={titleRef} className="display-5 mb-1 font-weight-bold text-left text-md-center ">
                {title}
              </h1>

              <div className="mb-1 mt-1 text-left text-md-center" style={{}}>
                {tags.split(' ').map((item, index) => {
                  return (
                    <p key={index} className="d-inline-block pr-2 mb-0 text-muted" style={{ fontSize: '1rem' }}>
                      {item}
                    </p>
                  )
                })}
              </div>
            </div>

            <div className="col-12 col-md-6 mt-4 mt-md-0">
              {byline && (
                <div className="">
                  <p className="mr-2 font-weight-bold" style={{ color: 'black' }}>
                    The Project
                  </p>

                  <p className="mt-1 pr-4 " style={{ whiteSpace: 'pre-wrap' }}>
                    {byline}
                  </p>
                </div>
              )}
            </div>

            <div className="col-12 col-md-6 mt-2">
              <div ref={txtRef} className="" style={{}}>
                {role && (
                  <>
                    <div className="">
                      <p className="mr-2 font-weight-bold " style={{ color: 'black' }}>
                        My part
                      </p>

                      <p className="mt-1 mb-4 ">{role}</p>

                      {details && (
                        <>
                          <p className="mb-2 mr-2 pt-2 font-weight-bold " style={{ color: 'black' }}>
                            Credits
                          </p>
                          <p className="mt-1 mb-3 " style={{ whiteSpace: 'pre-wrap' }}>
                            {details}
                          </p>
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Waypoint>
  )
}

export default Block
