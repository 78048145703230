/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 07-06-2019.
 */

//import ReactPlayer from 'react-player'

import React, { useEffect, useRef, useState } from 'react'
import { useInterval } from 'react-use'
import './video-player.scss'

const Player = ({ url, toggleBuffer }) => {
  const videoRef = useRef()

  const onCanPlay = () => {
    console.log(' Player > canPLAY = ')
  }

  const onCanPlayThrough = () => {
    console.log(' Player > onCanPlayThrough = ')
    toggleBuffer(false)
  }

  const onLoadedData = (e) => {
    console.log(' Player > onLoadedData = ', e)
  }

  const onPlaying = (e) => {
    //console.log (" Player > onPlaying = " ,e.currentTarget.buffered.start );
    /*	var result = [];
			for (var i = 0; i < e.currentTarget.buffered.length; i++) {
				result.push({
					start: e.currentTarget.buffered.start(i),
					end: e.currentTarget.buffered.end(i),
				});
			}*/
    //console.log (" Player > result = " , result);
  }

  const onTimeUpdate = (e) => {
    //console.log (" Player > onTimeUpdate  = " ,e );
    //console.log (" Player > e.currentTarget.currentTime = " , e.currentTarget.currentTime);
    /*const buffered = videoRef.current.time;
		var result = [];
		for (var i = 0; i < buffered.length; i++) {
			result.push({
				start: buffered.start(i),
				end: buffered.end(i),
			});
		}
		console.log (" Player > asda = duration = ",  result[0].start, "end: ", result[0].end );
*/
  }

  const onLoadedMetadata = (e) => {
    console.log(' Player > e = ', e)
  }

  return (
    <div className="react-player w-100 h-100 block-shadow">
      <video
        ref={videoRef}
        playsInline
        autoPlay="autoplay"
        muted="muted"
        onLoadedData={onLoadedData}
        onCanPlay={onCanPlay}
        onCanPlayThrough={onCanPlayThrough}
        onPlaying={onPlaying}
        onTimeUpdate={onTimeUpdate}
        onLoadedMetadata={onLoadedMetadata}
        loop="loop"
        style={{ width: '100%', height: '100%' }}
      >
        <source src={url} type="video/mp4" />
      </video>
    </div>
  )
}

export default Player
