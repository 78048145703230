/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 19-08-2019.
 */
import React from 'react'
/*.divider{
	width:2rem;
	height:1px;
	background: #b5b5b5;
}*/

const Divider = React.forwardRef(({ height = 1, color = 'black', width = '2rem', opacity = 1 }, ref) => {
  return (
    <div
      ref={ref}
      className="mt-4 mb-2"
      style={{ background: color, width: width, height: height, opacity: opacity }}
    ></div>
  )
})

export default Divider
