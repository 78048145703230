import React, { useEffect } from 'react'

import Block from './components/block/Block'
import { withBreakpoints } from 'react-breakpoints'

const AppUseSprings = ({ data, breakpoints, currentBreakpoint }) => {
  const isMobile = breakpoints[currentBreakpoint] < breakpoints.tablet

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      window.scrollTo(0, 0)
    }
  })

  let activeIndex = 0
  const itemLeft = (v) => {
    console.log(' AppUseSprings > call = ')
    activeIndex = v
  }

  return (
    <div className="container-fluid w-100 h-100 overflow-hidden" style={{ maxWidth: 1600 }}>
      {data.map((item, index) => {
        return <Block key={index} itemLeft={itemLeft} item={item} index={index} isMobile={isMobile} />
      })}
    </div>
  )
}

export default withBreakpoints(AppUseSprings)
