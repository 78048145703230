import React from 'react'
import ReactBreakpoints from 'react-breakpoints'

import nbaFan from './assets/tv2-fan.mp4'
import finewiresTwo from './assets/finewires_2.jpg'
import finewiresOne from './assets/finewires_1.jpg'
import finewiresFive from './assets/finewires_5.jpg'
import finewiresFour from './assets/finewires_4.jpg'
import finewiresThree from './assets/finewires_3.jpg'
import bingoThree from './assets/bingo_3.jpg'
import bingoImage from './assets/bingo.jpg'
import bingoTwo from './assets/bingo_2.jpg'
import bingoFour from './assets/bingo_4.jpg'

import cineOne from './assets/cinemataztic_1.jpg'

import krible from './assets/mixed_krible.jpg'
import change from './assets/change.jpg'
import hitman from './assets/hitman.jpg'
import storeBaelt from './assets/store-baelt.jpg'

import AppUseSprings from './AppUseSprings'
import { ReactComponent as InstaIcon } from './assets/instagram.svg'
import { ReactComponent as LinkedInIcon } from './assets/linkedin.svg'
import ReactGA from 'react-ga'

const data = [
  /*{
    title: 'TV2 FAN',
    byline:
      'A second screen quiz platform for LIVE sports events. TV2 Fan lets sports fans answer questions and interact with the screen and television hosts in real time.\n \n' +
      'TV2 FAN became an integrated part of watching sports on TV 2 and was used for NBA basket, Handball VM 2017, UEFA EM 2016, Ice Hockey and Tour de France',

    details:
      'Idea: Mikkel Hagedorn. Concept/UX: Anders Søndergaard, Dennis C. UI/Design: Dennis C. Frontend/Backend/DevOps: Dennis C.\n' +
      'Credits: Bjarke Hjort TV2 Project owner ',

    role:
      'I designed UI/UX and build the complete platform, frontend, backend and devops. Including onscreen graphic and the admin dashboard for controlling the platform while On Air.',
    tags: '#Platform #NodeJS #MongoDB #Redis #Angular #AWS #Websockets',
    maskColor: '#050832',
    aspect: '180%',
    images: [blandet],
  },*/

  {
    title: 'TV2 FAN',
    byline:
      'A second screen quiz platform for LIVE sports events. TV2 Fan lets sports fans answer questions and interact with the screen and television hosts in real time.\n \n' +
      'TV2 FAN became an integrated part of watching sports on TV 2 and was used for NBA basket, Handball VM 2017, UEFA EM 2016, Ice Hockey and Tour de France',
    video: nbaFan,
    details: 'Idea: Mikkel Hagedorn. Concept/UX: Anders Søndergaard. TV2 Project owner: Bjarke Hjort',

    role:
      'I designed UI/UX and build the complete platform, frontend, backend and devops. Including onscreen graphic and the admin dashboard for controlling the platform while On Air.',
    tags: '#Platform #NodeJS #MongoDB #Redis #Angular #AWS #Websockets',
    maskColor: '#050832',
  },

  {
    title: 'TV2 Reklamebingo ',
    tags: '#Platform #nodeJS #MongoDB #Redis #Angular #WebGL #AWS #Websockets #NumberOneInAppStore',
    images: [bingoThree, bingoTwo, bingoImage, bingoFour],
    byline:
      'A second screen platform enhancing the commercial value of flow tv by letting the user interacts with ads during the commercial breaks.\n \n' +
      'TV2 ReklameBingo was activated on every commercial break on TV2 and users had an increase in 40% on how well they remembered the commercial.',

    details: 'Idea: Mikkel Hagedorn. TV2 Project owners: Anders Schaffner, Katrine Sumborg',
    role:
      'I designed UI/UX, build the App and complete platform, frontend, backend and devops. This also include an admin interface for managing prizes, users, brands and the integration with TV2 commecial booking system.',
    maskColor: '#E72523',
  },

  {
    title: 'Finewires',
    role: 'Co-founder. I designed UI/UX/branding and build the complete platform',
    images: [finewiresTwo, finewiresOne, finewiresFive, finewiresFour, finewiresThree],
    byline:
      'Finewires is a different take on an automated influencers platform. Where its It’s no longer about the amount of followers.\n \nUsing our custom OCEAN test to rate each influencer who signs up to our platform, we are able to score an influencer by their firstmover potential and not solely by the amount of followers.',

    details: 'Idea: Mikkel Hagedorn and Kirsten Poulsen from Firstmove.',
    tags: '#Platform #NodeJS #MongoDB #React #AWS',
    maskColor: '#1539AB',
  },

  {
    title: 'Cinemataztic.com',
    byline:
      'Cinemataztic is the company behind BioSpil. A platform which enables you to play games on the cinema screen right before the movie starts. We set out to build a highly customizable website using the open source Netlify CMS ',
    tags: '#Website #CMS #Gatsbyjs #NetlifyCMS #GraphQL #React',
    role: 'I did the design and build the website and CMS on top of NetlifCMS.',

    maskColor: '#CB3F5D',
    images: [cineOne],
  },
  {
    title: 'Older projects',
    byline: 'As a freelancer i have worked on multiple projects through the last 14 years.',
    role: 'A collection of older projects.',
    tags: '#Website #Game #App #DR #IO-Interactive #StatensNaturhistoriskeMuseum',

    url: cineOne,
    images: [change, krible, hitman, storeBaelt],
    maskColor: '#CB3F5D',
  },

  /*{
		title: "Boomerang effect",
		byline:"Using gphoto2 and FFmpeg to convert the video to a series of images/frames. " +
					 "Then by using a simple easing equation, we pick the images matching the easing value at a given time. " +
					 "The selected frames are duplicated and reversed and finally encoded back to a video using FFmpeg.",
		tags:  "#photobooth #playtime #nodejs #instagram",

		video:boomerang,
	},*/
]

const breakpoints = {
  mobile: 320,
  tablet: 768,
  desktop: 1200,
  desktopLarge: 1500,
  desktopWide: 1920,
}

const App = (props) => {
  const instaClick = (e) => {
    e.preventDefault()

    ReactGA.event({
      category: 'go to:',
      action: 'instagram click ',
      //label: "show image" + selectedIndex.current
    })

    window.open('https://www.instagram.com/meresukker', '_blank')
  }
  const linkedinClick = () => {
    ReactGA.event({
      category: 'go to:',
      action: 'instagram click ',
      //label: "show image" + selectedIndex.current
    })

    window.open('https://www.linkedin.com/in/ddennis', '_blank')
  }

  return (
    <ReactBreakpoints breakpoints={breakpoints}>
      <AppUseSprings data={data}> </AppUseSprings>

      <div className="container-fluid w-100 h-100 mb-4">
        <div className="row" style={{}}>
          <div className="col-12 col-md-10 mx-auto">
            <hr />
          </div>
        </div>
        <div className="row footer" style={{}}>
          <div
            className="col-8 mx-auto mt-5 mb-5 d-flex justify-content-center align-items-center"
            style={{ height: '40vh' }}
          >
            <div className="">
              <h1 className="display-4 mb-3 text-center">Have a project or want to talk.</h1>
              <h5 className="text-center">
                Get in touch <span className="font-weight-bold">dennis@fantastisk.dk</span>
              </h5>
              <div className="d-flex justify-content-center mt-4 ">
                <div onClick={instaClick} className="ml-2 pointer" style={{ width: 30, height: 30 }}>
                  <InstaIcon className="img-fluid" />
                </div>
                <div onClick={linkedinClick} className="ml-2 pointer" style={{ width: 30, height: 30 }}>
                  <LinkedInIcon className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*<div className="row ">
          <div className="col-md-10 col-12 mx-auto mb-3" style={{}}>
            <div className="row" style={{}}>
              <div className="col-12 w-100" style={{}}>
                <hr />

                <div className="d-flex justify-content-between mt-4">
                  <div className="" style={{}}>
                    <p style={{ opacity: 1, fontSize: '.9rem' }}>
                      Get in touch: <b>dennis@fantastisk.dk </b>
                    </p>
                  </div>
                  <div></div>

                  <div className="" style={{ marginTop: -5 }}>
                    <div className="d-flex w-100 ">
                      <div onClick={instaClick} className="ml-2 pointer" style={{ width: 30, height: 30 }}>
                        <InstaIcon className="img-fluid" />
                      </div>
                      <div onClick={linkedinClick} className="ml-2 pointer" style={{ width: 30, height: 30 }}>
                        <LinkedInIcon className="img-fluid" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>*/}
      </div>
    </ReactBreakpoints>
  )
}

export default App
