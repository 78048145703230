/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 01-10-2019.
 */
import React, { useEffect, useRef } from 'react'
import gsap from 'gsap'
import { useGesture } from 'react-use-gesture'
import Arrow from './Arrow'
import ReactGA from 'react-ga'

const BlockGallery = ({ items, inView, index, title }) => {
  const myElements = useRef([])

  let selectedIndex = useRef(0)
  let myInterval = useRef(0)

  const next = () => {
    if (myElements.current[0] === null) {
      return
    }

    clearTimeout(myInterval.current)
    const item = myElements.current[selectedIndex.current]

    selectedIndex.current = selectedIndex.current + 1 >= items.length ? 0 : selectedIndex.current + 1

    const newItem = myElements.current[selectedIndex.current]

    fadeImage(newItem, item, 1)
    ReactGA.event({
      category: title,
      action: 'navigate forward' + title,
      label: 'show image' + selectedIndex.current,
    })
  }

  const prev = () => {
    if (myElements.current[0] === null) {
      return
    }

    ReactGA.event({
      category: title,
      action: 'navigate back',
      label: 'show image ',
    })

    clearTimeout(myInterval.current)
    const item = myElements.current[selectedIndex.current]

    selectedIndex.current = selectedIndex.current - 1 < 0 ? items.length - 1 : selectedIndex.current - 1

    const newItem = myElements.current[selectedIndex.current]

    fadeImage(newItem, item, -1)
    ReactGA.event({
      category: title,
      action: 'navigate back' + title,
      label: 'show image' + selectedIndex.current,
    })
  }

  const fadeImage = (item, oldItem, dir) => {
    gsap.killTweensOf(oldItem)
    gsap.to(oldItem, 0.5, { x: 200 * (dir * -1), opacity: 0, ease: 'Expo.easeOut' })
    gsap.killTweensOf(item)

    gsap.fromTo(
      item,
      0.8,
      { x: dir * 300, opacity: 0, ease: 'Expo.easeOut' },
      { x: 0, opacity: 1, ease: 'Expo.easeOut' }
    )
  }

  useEffect(() => {
    myInterval.current = setTimeout(() => {
      next()
    }, 2000)
    //}
  }, [inView])

  const bind = useGesture({
    onDrag: ({ active, distance, movement, cancel }) => {
      const item = myElements.current[selectedIndex.current]
      console.log(' BlockGallery > what = ')
      if (!active) {
        cancel()
        return
      }

      if (active && distance < 10) {
        return
      }

      const to = movement[0]

      if (to > 75 && active) {
        cancel()
        prev()
        return
      }

      if (to < -75 && active) {
        cancel()
        next()
        return
      }
    },
  })

  return (
    <div {...bind()} className="h-100 w-100 position-absolute draggable" style={{ top: 0, left: 0 }}>
      {items.map((item, index) => {
        return (
          <div
            {...bind()}
            ref={(li) => (myElements.current[index] = li)}
            key={index}
            className="back-image block-shadow h-100 w-100 position-absolute"
            style={{
              backgroundImage: 'url(' + item + ')',
              opacity: index === 0 ? 1 : 0,
              top: 0,
              left: 0,
              userSelect: 'none',
            }}
          ></div>
        )
      })}

      <div className="position-absolute w-100 h-100  " style={{ top: 0, left: 0, zIndex: 500 }}>
        <div className="d-flex justify-content-between align-items-center h-100 ">
          <div
            onClick={prev}
            className="overflow-hidden gallery-arrow-left "
            style={{ width: 60, height: 50, zIndex: 220, transform: 'rotate(180deg)' }}
          >
            <Arrow></Arrow>
          </div>

          <div
            onClick={next}
            className="overflow-hidden gallery-arrow-right"
            style={{ width: 60, height: 50, zIndex: 220 }}
          >
            <Arrow></Arrow>
          </div>
        </div>
      </div>
    </div>
  )
}
export default BlockGallery
