/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 01-10-2019.
 */

import React , {useRef} from "react";
import { useHover } from 'react-use-gesture'
import { TweenMax } from "gsap";


 const Arrow = (props) => {

 	const arrowOne = useRef();
 	const arrowTwo = useRef();

	 const bind = useHover(({hovering}) => {

	 	if(hovering ){
			TweenMax.to(arrowOne.current, .4,{x:-10 ,  ease:"Back.easeOut"})
			TweenMax.to(arrowTwo.current, .5,{x:80, ease:"Expo.easeOut"})
	 	}else{

			TweenMax.to(arrowOne.current, .2,{x:-50 , ease:"Expo.easeOut"})
			TweenMax.to(arrowTwo.current, .2,{x:-10, ease:"Expo.easeOut"})
	 	}


	 });


 	return (
 	   <div	className="d-flex  position-relative pointer " style={{paddingTop:14, height:50, width:60}} {...bind()}>

		   <div ref={arrowOne} className="position-absolute" style={{transform:"translate3d(-50px,0px,0px)", height:20}}>
			   <svg width="60" height="18" xmlns="http://www.w3.org/2000/svg" ><path d="M44.102 4l-2.444-2.445.009-1.405 4.325 4.325-4.38 4.38.01-1.423L44.101 5H.002V4z" fillRule="evenodd"></path></svg>
		   </div>

		   <div ref={arrowTwo} className="" style={{transform:"translate3d(-10px,0px,0px)",height:20}}>
			   <svg width="60" height="18" xmlns="http://www.w3.org/2000/svg" ><path d="M44.102 4l-2.444-2.445.009-1.405 4.325 4.325-4.38 4.38.01-1.423L44.101 5H.002V4z" fillRule="evenodd"></path></svg>
		   </div>


	   </div>

 	)

 }
 export default Arrow
